class quotation{
	constructor(){
		this.storage = 'akt-data';
		this.sidebar = 'quote-sidebar';

		this.ids = [];

		/*let prevData = this.getData();
		if( prevData ){
			prevData.map( v=>{
				this.ids.push(v.id);
			})
		}*/
		
		this.template = jQuery( '#quote-template' ).html();

		/*jQuery( document ).on( 'wpcf7mailsent', ( e ) => {
			if( AKTIVMILJO.quote_form_id == e.detail.contactFormId ){
				this.setData('');
				this.refresh();
				this.ids = [];
			}
		});*/

		// Update local storage when qty changes		
		/*jQuery(document).on('quantity.changed',(e, $this, v)=>{
			
			if( $this.closest('#quote-sidebar').length ){
				let pos = jQuery('#quote-sidebar ul li').index( $this.closest('li') );
				let data = this.getData();
				data[pos].qty = v;
				this.setData(data);
				this.addToForm(data);
				console.log( data );
			}
		});*/

		jQuery(document).on( 'click' ,'.add-quote', function(e){

		    e.preventDefault();

		    var $t = jQuery(this),
		        $t_wrap = $t.parents('.yith-ywraq-add-to-quote'),
		        add_to_cart_info = 'ac';


		    if ( $t.parents('ul.products').length > 0) {
		        var $add_to_cart_el = $t.parents('li.product').find('input[name="add-to-cart"]'),
		            $product_id_el = $t.parents('li.product').find('input[name="product_id"]');
		    }else{
		        var $add_to_cart_el = $t.parents('.product').find('input[name="add-to-cart"]'),
		            $product_id_el = $t.parents('.product').find('input[name="product_id"]');
		    }


		    if ($add_to_cart_el.length > 0 && $product_id_el.length > 0) { //variable product
		        add_to_cart_info = jQuery('.cart').serialize();
		    } else if ( jQuery('.cart').length > 0) { //single product and form exists with cart class
		        add_to_cart_info = jQuery('.cart').serialize();
		    }


		    add_to_cart_info += '&action=yith_ywraq_action&ywraq_action=add_item&product_id='+$t.data('product_id')+'&wp_nonce='+$t.data('wp_nonce');
		    if( add_to_cart_info.indexOf('add-to-cart') > 0){
		        add_to_cart_info = add_to_cart_info.replace( 'add-to-cart', 'yith-add-to-cart');
		    }

		    jQuery.ajax({
		        type   : 'POST',
		        url    : ywraq_frontend.ajaxurl,
		        dataType: 'json',
		        data   : add_to_cart_info,
		        beforeSend: function(){
		            $t.siblings( '.ajax-loading' ).css( 'visibility', 'visible' );
		        },
		        complete: function(){
		            $t.siblings( '.ajax-loading' ).css( 'visibility', 'hidden' );
		        },

		        success: function (response) {
		            if( response.result == 'true' || response.result == 'exists'){

	                    jQuery( '#quote-sidebar' ).html( '' );
	                    jQuery( '.akt-quote-icon span.akt-cart-counter' ).html( response.count );
	                    	// console.log('1 ' + response.sidebar );
		                if( response.sidebar != null ) {		                	
	                    	jQuery( '#quote-sidebar' ).html( response.sidebar );

                            if( "undefined" != typeof grecaptcha && jQuery("#akt-g-captcha").length > 0) {

		                    	grecaptcha.render(
								    "akt-g-captcha",
								    {
								    	site_key: "6LdXy50UAAAAAM_vZLn-M_brmhfJPSmQzLUWMvI6",
								    }
								);
							}
	                    	jQuery('#quote-sidebar input.qty').each(function() {
	                    	    if( !jQuery(this).attr('readonly') ){		            
	                    	        if ( jQuery(this).closest('.quantity').find('.fa-angle-up').length < 1) {
	                    	        	jQuery(this).before('<i class="fa fa-angle-up up"></i>').after('<i class="fa fa-angle-down down"></i>');
	                    	        }
	                    	    }
	                    	});

		                } 
		                $t.parent().hide().removeClass('show').addClass('addedd');
		                var prod_id = ( typeof $product_id_el.val() == 'undefined') ? '' : '-'+$product_id_el.val();
		                $t_wrap.append( '<div class="yith_ywraq_add_item_response'+ prod_id +' yith_ywraq_add_item_response_message">' + response.message + '</div>');
		                $t_wrap.append( '<div class="yith_ywraq_add_item_browse-list'+prod_id+' yith_ywraq_add_item_browse_message"><a href="'+response.rqa_url+'">' + response.label_browse + '</a></div>');
		                jQuery('#akt-quote-open').trigger('click');

		            }else if( response.result == 'false' ){
		                $t_wrap.append( '<div class="yith_ywraq_add_item_response-'+$product_id_el.val()+'">' + response.message + '</div>');
		            }
		        }
		    });

		});
		
		/*remove*/
		jQuery(document).on( 'click' ,'.akt-yith-ywraq-item-remove', function(e){
		    e.preventDefault();

		    var $t = jQuery(this),
		        key = $t.data('remove-item'),
		        form = jQuery('#yith-ywraq-form'),
		        remove_info = '';

		    remove_info = 'action=yith_ywraq_action&ywraq_action=remove_item&key='+$t.data('remove-item')+'&wp_nonce='+$t.data('wp_nonce')+'&product_id='+$t.data('product_id');

		    jQuery.ajax({
		        type   : 'POST',
		        url    : ywraq_frontend.ajaxurl,
		        dataType: 'json',
		        data   : remove_info,
		        beforeSend: function(){
		            $t.siblings( '.ajax-loading' ).css( 'visibility', 'visible' );
		        },
		        complete: function(){
		            $t.siblings( '.ajax-loading' ).css( 'visibility', 'hidden' );
		        },

		        success: function (response) {
		            if( response.result ){
		                
		                jQuery( "[data-remove-item='" + key + "']" )
		                .parents( '.cart_item' )
		                .remove();
		                
		                if( jQuery( '.cart_item' ).length === 0 ) {
		                    jQuery( '#yith-ywraq-form, #yith-ywraq-mail-form, .yith-ywraq-mail-form-wrapper' ).remove();
		                    jQuery( '#yith-ywraq-message' ).text( ywraq_frontend.no_product_in_list );
		                }
	                    jQuery( '#quote-sidebar' ).html( '' );
		                if( response.data != null ) {		                	
	                    	jQuery( '#quote-sidebar' ).html( response.data );
	                    	

    						if( "undefined" != typeof grecaptcha && jQuery("#akt-g-captcha").length > 0 ) {
    							
    	                    	grecaptcha.render(
    							    "akt-g-captcha",
    							    {
    							    	site_key: "6LdXy50UAAAAAM_vZLn-M_brmhfJPSmQzLUWMvI6",
    							    }
    							);
    						}

	                    	jQuery( '.akt-quote-icon span.akt-cart-counter' ).html( response.count );


	                    	jQuery('#quote-sidebar input.qty').each(function() {
	                    	    if( !jQuery(this).attr('readonly') ){		            
	                    	        if ( jQuery(this).closest('.quantity').find('.fa-angle-up').length < 1) {
	                    	        	jQuery(this).before('<i class="fa fa-angle-up up"></i>').after('<i class="fa fa-angle-down down"></i>');
	                    	        }
	                    	    }
	                    	});

		                } 
		            }
		        }
		    });
		});


		jQuery( document ).on( 'click' , '.show-quote-sidebar' , (e) => {
			e.preventDefault();
			jQuery('#akt-quote-open').trigger('click');
		});

		// Close the slider if clicked to overlay
		jQuery( document ).on( 'click', '.akt-close-icon, #siteOverlay', () => {
			this.close();
		});

		// Open the slider
		jQuery( document ).on( 'click', '#akt-quote-open', () => {
		
			this.open();
		});
		
		// Delete the data
		jQuery(document).on('click', '.akt-quote-del', e =>{
			e.preventDefault();
			let id = jQuery( e.target ).attr( 'data-id'); 
			this.delete(id);
		});	
		

		if( AKTIVMILJO.user.ID > 0 ){
			jQuery( '#akt-get-quote-slide input[type="email"]' ).val( AKTIVMILJO.user.data.user_email );
		}
	}	

	close(){
		jQuery( '#akt-get-quote-slide' ).addClass('close');
		jQuery('#siteOverlay').remove();
		jQuery( 'body' ).removeClass('cart-open');    
		jQuery('.akt-sticky-contact-form').removeClass('akt-hidecontact-btn');    
		jQuery('.akt-search-block').css('display','block');   
	}

	open(){

		jQuery( 'body' ).addClass('quote-sidebar-open');
		// this.numberCustom();

		jQuery('#akt-get-quote-slide').toggleClass('close');
		jQuery( 'body' ).addClass('cart-open');
		
		jQuery( '#akt-get-quote-slide' ).after('<div id="siteOverlay"></div>');
		jQuery('#siteOverlay').toggleClass('active');
		jQuery('.akt-sticky-contact-form').toggleClass('akt-hidecontact-btn');
		jQuery('.akt-search-block').css('display','none');
	}
}

new quotation();